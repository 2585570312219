import './Vision.scss';
// import Dinner form
import useScript from '../../helpers/bloomerang';
// import { Helmet } from "react-helmet";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useState } from 'react';
import { useEffect } from 'react';


// import images
import whitelinenbackground from '../../assets/images/whitelinenbackground.png'
import Logo from '../../assets/images/iconlogo.png';

export default function Vision() {

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);



    // load script
    // useExternalScripts("https://s3-us-west-2.amazonaws.com/bloomerang-public-cdn/jasminerhosefoundation/.widget-js/1189888.js");

    // DINNER FORM
    // const data='<script src="https://s3-us-west-2.amazonaws.com/bloomerang-public-cdn/jasminerhosefoundation/.widget-js/1189888.js" type="text/javascript"></script>'
    // const DinnerForm = () => {
    //     useEffect(() => {
    //         // Create a script element
    //         const script = document.createElement('script');

    //         // Set the script source to the remote-widgets.js file
    //         script.src = 'https://s3-us-west-2.amazonaws.com/bloomerang-public-cdn/jasminerhosefoundation/.widget-js/1189888.js';
    //         script.async = true;

    //         // Append the script to the document body
    //         document.body.appendChild(script);

    //         // Load the DinnerForm script after the remote-widgets.js has loaded
    //         script.onload = () => {
    //             const remoteDinnerForm = document.createElement('script');
    //             remoteDinnerForm.text = `DinnerForm`;

    //             // Append the DinnerForm script to the document body
    //             document.body.appendChild(remoteDinnerForm);
    //         };
    //         // Cleanup: Remove the script elements when the component unmounts
    //         return () => {
    //             document.body.removeChild(script);
    //         };
    //     }, []);
    // };

    // useEffect(() => {
    //     const script = document.createElement('script');

    //     script.src = "http://s3-us-west-2.amazonaws.com/bloomerang-public-cdn/jasminerhosefoundation/.widget-js/1189888.js";
    //     script.type = 'text/javascript';
    //     script.crossOrigin='Anonymous';
    //     script.async = true;

    //     document.body.appendChild(script);

    //     return () => {
    //       document.body.removeChild(script);
    //     }
    //   }, []);


    // useEffect(() => {
    //     // Create a script element
    //     const script = document.createElement('script');

    //     // Set the script source to the remote-widgets.js file
    //     script.src = 'https://s3-us-west-2.amazonaws.com/bloomerang-public-cdn/jasminerhosefoundation/.widget-js/54272.js';
    //     script.async = true;

    //     // Append the script to the document body
    //     document.body.appendChild(script);

    //     // Load the RemoteCalc script after the remote-widgets.js has loaded
    //     script.onload = () => {
    //         const myDinnerForm = document.createElement('script');
    //         myDinnerForm.text = `startBloomerangLoad()`;

    //         // Append the RemoteCalc script to the document body
    //         document.body.appendChild(myDinnerForm);

    //         // Cleanup: Remove the script elements when the component unmounts
    //         return () => {
    //             document.body.removeChild(script);
    //         };
    //     },[]);
    //     return <div></div>
    // useExternalScripts('https://s3-us-west-2.amazonaws.com/bloomerang-public-cdn/jasminerhosefoundation/.widget-js/54272.js')


    // const DinnerForm =useScript('https://s3-us-west-2.amazonaws.com/bloomerang-public-cdn/jasminerhosefoundation/.widget-js/1189888.js', 'Bloomerang')



    return (
        <div className='container' style={{ margin: 'auto' }}>




            <div className='container font-format ' style={{ margin: 'auto', paddingTop: '5vh' }}>

                <h1 className='givingTitle'>Jasmine Rhose Foundation Vision Dinner 2024</h1>

                <div className='givingText'>
                    <br />
                    <p>
                        Curious about the heartbeat of this ministry? Join us on September 27, 2024 at the Meridian Banquet & Conference Center in Rolling Meadows, Illinois for a complimentary dinner and an opportunity to hear the founder's vision.
                        There will be an opportunity to give. Adults only please.

                    </p>

                    <br />
                    <div>
                        <b className='greenText'>
                            Date and Time:
                        </b>
                        <br />
                        <p>September 27, 2024 at 6:30 pm</p>
                    </div>
                    {/* <br /> */}
                    <div >
                        <b className='greenText'>Address:</b>
                        <p>
                            Meridian Banquet & Conference Center
                            <br />
                            1701 W Algonquin Rd, Rolling Meadows, IL 60008
                        </p>
                    </div>
                    {/* <br /> */}
                    <div >
                        <b className='greenText'> Attire: </b> <br />
                        <p>Formal Business Attire</p>
                    </div>
                    <br />

                </div>

                <b className='givingText greenText'> Since seating is limited, reservation is required.</b>
                <div>
                    <br />
                </div>
            </div>

            <button className='supportBtn' style={{ color: 'white', textDecoration: 'none',}} onClick={handleShow3} >
                R.S.V.P

                {/* <a href='https://secure.lglforms.com/form_engine/s/K0Xw4BRIuHDJ3k4cwcCJDA' style={{ color: 'white', textDecoration: 'none' }}>R.S.V.P.</a> */}
                {/* <a href='./form.html' style={{ color: 'white', textDecoration: 'none' }}>R.S.V.P.</a> */}
            </button>
            <Modal show={show3} onHide={handleClose3}>
                <Modal.Header closeButton style={{backgroundImage:`url(${whitelinenbackground})`}}>
                    <img src={Logo} style={{ width: '7rem', margin: '1px' }}></img>

                    <Modal.Title className='donateHeader' style={{ marginLeft: '20px', fontSize: '3rem', color:'black'  }}>R. S. V. P.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ position: 'relative', overflow: 'hidden', width: '100%', height: '1200px', paddingTop: '100%',}} >
                        <iframe title='Jasmine Rhose' 
                        style={{ position: 'absolute', border: '0', top: '0', left: '0', bottom: '0', right: '0', width: '100%', height: '100%',}} 
                      
                        src='./form.html'>
                        </iframe>

                        {/* <iframe title='Jasmine Rhose' style={{ position: 'absolute', border: '0', top: '0', left: '0', bottom: '0', right: '0', width: '100%', height: '100%'}} src='https://crm.bloomerang.co/Content/Scripts/Api/Bloomerang-v2.js?nocache=1712081942'  >
                        </iframe> */}




                     </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose3}>
                        Close
                    </Button>

                </Modal.Footer>


            </Modal> 

            <br />

            <div className='givingText'>


            </div>



            <div style={{ paddingBottom: '1rem' }}>
                <br />
            </div>

            <div>
               
            
            </div>



        </div>



    );
}