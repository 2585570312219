import { useState, useEffect } from 'react'
const useScript = (url, name) => {

  const [lib, setLib] = useState({})

  useEffect(() => {
    const script = document.createElement('script')

    script.src = url
    script.async = true
    script.onload = () => setLib({ [name]: window[name] })

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [url])

  return lib

}


export default useScript;







// import React, { useEffect } from 'react';

// const DinnerForm = () => {
//     useEffect(() => {
//         // Create a script element
//         const script = document.createElement('script');

//         // Set the script source to the remote-widgets.js file
//         script.src = 'https://s3-us-west-2.amazonaws.com/bloomerang-public-cdn/jasminerhosefoundation/.widget-js/1189888.js';
//         script.async = true;

//         // Append the script to the document body
//         document.body.appendChild(script);

//         // Load the RemoteCalc script after the remote-widgets.js has loaded
//         script.onload = () => {
//             const remoteDinnerForm = document.createElement('script');
//             remoteDinnerForm.text = `Dinner Registration Form`;

//             // Append the RemoteCalc script to the document body
//             document.body.appendChild(remoteDinnerForm);
//         };

//         // Cleanup: Remove the script elements when the component unmounts
//         return () => {
//             document.body.removeChild(script);
//         };
//     }, []); // The empty dependency array ensures that this effect runs once on component mount

//     return <div id="dinner-form"></div>;
// };

// export default DinnerForm;